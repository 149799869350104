export function getCookieInClient(name: string): string | null {
  if (typeof window === 'undefined' || !document?.cookie) {
    return null;
  }

  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}

interface CookieOptionsType {
  name: string;
  value: string;
  expires?: Date;
  path?: string;
}

interface CookieDefaultOptionsType {
  httpOnly: boolean;
  secure: true;
  path: string;
  sameSite: 'strict' | 'lax';
}

const cookieDefaultOptions: CookieDefaultOptionsType = {
  httpOnly: false,
  secure: true,
  path: '/',
  sameSite: 'strict',
};

function getDefaultExpires(expires: Date | string) {
  let finalExpires = expires;
  if (typeof expires === 'string') {
    const date = new Date();
    date.setDate(date.getDate() + 5);
    finalExpires = date;
  }
  return finalExpires as Date;
}

export function createCookieOptions(options: CookieOptionsType) {
  const cookieSettings = {
    ...cookieDefaultOptions,
    ...options,
  };

  return cookieSettings;
}

export function createCookieConfig(options: {
  path?: string;
  expires: string | Date;
}) {
  const cookieSettings = {
    ...cookieDefaultOptions,
    ...options,
    expires: getDefaultExpires(options.expires),
  };

  return cookieSettings;
}
