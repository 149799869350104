import { LocaleCodesType, LanguageCodesType, RegionCodesType } from 'utils';

export const defaultLocale = 'en-sa';
export const defaultLanguage = 'en';
export const defaultRegion = 'SA';
export const locales: Array<LocaleCodesType> = [
  'en-sa',
  'ar-sa',
  'ar-ae',
  'en-ae',
];
export const defaultNS = 'common';
export const cookieName = 'locale';
export const headerLng = 'lng';
export const languages: Array<LanguageCodesType> = ['en', 'ar'];
export const regions: Array<RegionCodesType> = ['SA', 'AE'];

export const i18nConfig = {
  locales: locales,
  defaultLocale: defaultLocale,
  prefixDefault: true,
};

export function getOptions(locale = defaultLocale, ns = defaultNS) {
  return {
    lng: locale,
    fallbackLng: defaultLocale,
    supportedLngs: locales,
    defaultNS: defaultNS,
    fallbackNS: defaultNS,
    lowerCaseLng: true,
    ns: ns,
    preload: locales,
    debug: false,
  };
}
