export { default as FireIcon } from 'app/assets/icons/fire.svg';
export { default as CloseSmallIcon } from 'app/assets/icons/close-small.svg';
export { default as LocationIcon } from 'app/assets/icons/location.svg';
export { default as SearchIcon } from 'app/assets/icons/search.svg';
export { default as WarningIcon } from 'app/assets/icons/warning.svg';
export { default as CheckIcon } from 'app/assets/icons/check.svg';
export { default as UserIcon } from 'app/assets/icons/user.svg';
export { default as CartIcon } from 'app/assets/icons/cart.svg';
export { default as GiftIcon } from 'app/assets/icons/gift.svg';
export { default as SmallGiftIcon } from 'app/assets/icons/small-gift.svg';
export { default as HeartIcon } from 'app/assets/icons/heart.svg';
export { default as MenuIcon } from 'app/assets/icons/menu.svg';
export { default as ConsultIcon } from 'app/assets/icons/consult.svg';
export { default as PharmacistIcon } from 'app/assets/icons/pharmacist.svg';
export { default as HamburgerIcon } from 'app/assets/icons/hamburger-menu.svg';
export { default as CloseIcon } from 'app/assets/icons/close.svg';
export { default as CircularCancel } from 'app/assets/icons/circular-cancel.svg';
export { default as PlusIcon } from 'app/assets/icons/plus.svg';
export { default as MinusIcon } from 'app/assets/icons/minus.svg';
export { default as ArrowLeftIcon } from 'app/assets/icons/arrow-left.svg';
export { default as ChevronDownIcon } from 'app/assets/icons/chevron-down.svg';
export { default as ChevronRightIcon } from 'app/assets/icons/chevron-right.svg';
export { default as StarIcon } from 'app/assets/icons/star.svg';
export { default as VerifyIcon } from 'app/assets/icons/verify.svg';
export { default as TrashIcon } from 'app/assets/icons/trash.svg';
export { default as FacebookLogo } from 'app/assets/icons/facebook-logo.svg';
export { default as AppleLogo } from 'app/assets/icons/apple-logo.svg';
export { default as GoogleLogo } from 'app/assets/icons/google-logo.svg';
export { default as GoBackIcon } from 'app/assets/icons/go-back.svg';
export { default as AssistantIcon } from 'app/assets/icons/assistant.svg';
export { default as DropdownArrowIcon } from 'app/assets/icons/dropdown-arrow.svg';
export { default as RightArrowIcon } from 'app/assets/icons/right-arrow.svg';
export { default as LoginNahdiLogo } from 'app/assets/icons/login-nahdi-logo.svg';
export { default as MobileLoginNahdiLogo } from 'app/assets/icons/mobile-login-nahdi-logol.svg';
export { default as SaudiArabiaFlag } from 'app/assets/icons/saudi-arabia.svg';
export { default as EmiratesFlag } from 'app/assets/icons/emirates.svg';
export { default as AfghanistanFlag } from 'app/assets/icons/afghanistan.svg';
export { default as AlbaniaFlag } from 'app/assets/icons/albania.svg';
export { default as MobileBackButton } from 'app/assets/icons/mobile-back-button.svg';
export { default as DiscountIcon } from 'app/assets/icons/discount.svg';
export { default as PlayIcon } from 'app/assets/icons/play.svg';
export { default as CalendarIcon } from 'app/assets/icons/calender.svg';
export { default as PaymentCard } from 'app/assets/icons/payment-cards.svg';
export { default as BillIcon } from 'app/assets/icons/bill.svg';
export { default as BuyAgain } from 'app/assets/icons/buy-again.svg';
export { default as CheckList } from 'app/assets/icons/checklist.svg';
export { default as HearthIcon } from 'app/assets/icons/hearth.svg';
export { default as HomeLocation } from 'app/assets/icons/home-location.svg';
export { default as HomeLocationGray } from 'app/assets/icons/home-location-gray.svg';
export { default as LogoutIcon } from 'app/assets/icons/logout.svg';
export { default as UserRefundIcon } from 'app/assets/icons/user-refund.svg';
export { default as UserIconBlueCircle } from 'app/assets/icons/user-icon-blue-circle.svg';
export { default as CheckCircleIcon } from 'app/assets/icons/check-circle.svg';
export { default as SmallCloseIcon } from 'app/assets/icons/small-close.svg';
export { default as CouponIcon } from 'app/assets/icons/coupon.svg';
export { default as PaymentGroupIcon } from 'app/assets/icons/payment-group.svg';
export { default as CallIcon } from 'app/assets/icons/call.svg';
export { default as DiscountGiftIcon } from 'app/assets/icons/discount-gift.svg';
export { default as SecondaryLocationIcon } from 'app/assets/icons/secondary-location.svg';
export { default as ExpressServiceIcon } from 'app/assets/icons/express-service.svg';
export { default as GlobalServiceIcon } from 'app/assets/icons/global-service.svg';
export { default as CircleCloseIcon } from 'app/assets/icons/circle-close.svg';
export { default as UnlocksIcon } from 'app/assets/icons/unlocks.svg';
export { default as CvcIcon } from 'app/assets/icons/cvc.svg';
export { default as SecurityIcon } from 'app/assets/icons/security.svg';
export { default as SlotCalendarIcon } from 'app/assets/icons/slot-calendar.svg';
export { default as TimeIcon } from 'app/assets/icons/time.svg';
export { default as PickUpLocationIcon } from 'app/assets/icons/pickup-location.svg';
export { default as PickupDriveIcon } from 'app/assets/icons/pickup-drive.svg';
export { default as CheckInStockIcon } from 'app/assets/icons/check-in-stock.svg';
export { default as PartiallyAvailableStockIcon } from 'app/assets/icons/partially-available-stock.svg';
export { default as StorePickUpIcon } from 'app/assets/icons/store-pick-up.svg';
export { default as EditIcon } from 'app/assets/icons/edit.svg';
export { default as PointerIcon } from 'app/assets/icons/pointer.svg';
export { default as SecureIcon } from 'app/assets/icons/secure-icon.svg';
export { default as EprescriptionIcon } from 'app/assets/icons/e-prescription.svg';
export { default as FunnelIcon } from 'app/assets/icons/funnel.svg';
export { default as QRIcon } from 'app/assets/icons/qr-icon.svg';
export { default as PromotionTicketIcon } from 'app/assets/icons/promotion-ticket.svg';
export { default as FamilyHearthIcon } from 'app/assets/icons/family-hearth.svg';
export { default as CartDiscountIcon } from 'app/assets/icons/cart-discount.svg';
export { default as CoinsIcon } from 'app/assets/icons/coins.svg';
export { default as WhatsappIcon } from 'app/assets/icons/whatsapp.svg';
export { default as HearthIconLineThrough } from 'app/assets/icons/hearth-line-through.svg';
export { default as PhoneIcon } from 'app/assets/icons/phone.svg';
export { default as ChatIcon } from 'app/assets/icons/chat.svg';
export { default as CheckGreenIcon } from 'app/assets/icons/check-green.svg';
export { default as GlobalIconStripped } from 'app/assets/icons/global-icon-stripped.svg';
export { default as ExpressServiceIconStripped } from 'app/assets/icons/express-icon-stripped.svg';
export { default as FilterIcon } from 'app/assets/icons/filter.svg';
export { default as SortByIcon } from 'app/assets/icons/sort-by.svg';
export { default as CashIcon } from 'app/assets/icons/cash.svg';
export { default as InfoIcon } from 'app/assets/icons/info.svg';
export { default as StoreLocationBadge } from 'app/assets/icons/store-location-badge.svg';
export { default as DualWindowIcon } from 'app/assets/icons/dual-window.svg';
export { default as WindowColumnIcon } from 'app/assets/icons/window-column.svg';
export { default as HurryUpIcon } from 'app/assets/icons/hurry-up.svg';
export { default as CallPharmacist } from 'app/assets/icons/call_pharmacist.svg';
export { default as HomeDeliveryIcon } from 'app/assets/icons/home-delivery.svg';
export { default as StorePickupIcon } from 'app/assets/icons/store-pickup.svg';
export { default as LocationDetectIcon } from 'app/assets/icons/location-detect.svg';
export { default as StoreLocationIcon } from 'app/assets/icons/store-location.svg';
export { default as NotAvailableStoreLocationIcon } from 'app/assets/icons/not-available-location.svg';
export { default as PartiallyAvailableStoreLocationIcon } from 'app/assets/icons/partially-available-location.svg';
export { default as ConnectHomeIcon } from 'app/assets/icons/connect-home.svg';
export { default as MailIcon } from 'app/assets/icons/mail.svg';
export { default as MiniLogoIcon } from 'app/assets/icons/mini-logo.svg';
export { default as NuhdeekGiftIcon } from 'app/assets/icons/nuhdeek-gift.svg';
export { default as RxIcon } from 'app/assets/icons/rx-icon.svg';
export { default as MediceneIcon } from 'app/assets/icons/medicene.svg';
export { default as PrescriptionIcon } from 'app/assets/icons/presceription.svg';
export { default as LeftArrowWithCircle } from 'app/assets/icons/left-arrow-with-circle.svg';
export { default as MobilePasswordIcon } from 'app/assets/icons/mobile-password.svg';
export { default as HeartFilledIcon } from 'app/assets/icons/heart-filled.svg';
export { default as TopBrandsIcon } from 'app/assets/icons/top-brands.svg';
export { default as TrendingArrow } from 'app/assets/icons/trending-arrow.svg';
export { default as RecentTimeIcon } from 'app/assets/icons/recent_time.svg';
export { default as MagnifyingGlassIcon } from 'app/assets/icons/magnifying-glass.svg';
export { default as ScanIcon } from 'app/assets/icons/scan-icon.svg';
export { default as HomeIcon } from 'app/assets/icons/home-icon.svg';
export { default as MapInfoCloseIcon } from 'app/assets/icons/map-info-close-icon.svg';
export { default as SaudiFlagIcon } from 'app/assets/icons/saudi-arabia.svg';
export { default as EmiratesFlagIcon } from 'app/assets/icons/united-arab-emirates.svg';
export { default as KuwaitFlagIcon } from 'app/assets/icons/kuwait.svg';
export { default as MapViewIcon } from '/app/assets/icons/map-view.svg';
export { default as SoldOutIcon } from 'app/assets/icons/sold-out.svg';
export { default as OtpPhoneIcon } from 'app/assets/icons/otp-phone.svg';
export { default as WeakPasswordIcon } from 'app/assets/icons/weak-password.svg';
export { default as NahdiGiftIcon } from 'app/assets/icons/nahdi-gift.svg';
export { default as GlobalIcon } from 'app/assets/icons/global-icon.svg';
export { default as SmartFilterGlobalIcon } from 'app/assets/icons/sf-global.svg';
export { default as SmartFilterExpressIcon } from 'app/assets/icons/sf-express.svg';
export { default as SmartFilterDiscountIcon } from 'app/assets/icons/sf-discount.svg';
export { default as StorePickupPin } from 'app/assets/icons/store-pickup-location.svg';
export { default as FilledGreenCheckIcon } from 'app/assets/icons/filled-green-check.svg';
export { default as FilledGrayCheckIcon } from 'app/assets/icons/filled-gray-check.svg';
export { default as EyeIcon } from 'app/assets/icons/eye.svg';
export { default as SelectBoxChevronDown } from 'app/assets/icons/select-box-down.svg';
export { default as CrossedEyeIcon } from 'app/assets/icons/crossed-eye-icon.svg';
export { default as DriveThruIcon } from 'app/assets/icons/drive-thru.svg';
export { default as UploadIcon } from 'app/assets/icons/upload.svg';
export { default as NahdiClinicsLogo } from 'app/assets/icons/nahdi-clinics-logo.svg';
export { default as QuestionIcon } from 'app/assets/icons/question.svg';
export { default as CheckSingleIcon } from 'app/assets/icons/single-check.svg';
export { default as PDFIcon } from 'app/assets/icons/pdf.svg';
export { default as AddBoxIcon } from 'app/assets/icons/add-box.svg';
export { default as EarnNuhdeekPointsIcon } from 'app/assets/icons/earn-nuhdeek-points.svg';
export { default as EarnNuhdeekPointsGrayIcon } from 'app/assets/icons/nahdi-bg-icon.svg';
export { default as LimitReachedIcon } from 'app/assets/icons/limit-reached.svg';
export { default as InsuranceIcon } from 'app/assets/icons/insurance.svg';
export { default as CashMoneyIcon } from 'app/assets/icons/cash-money.svg';
export { default as CheckWhiteBgIcon } from 'app/assets/icons/check-white-bg.svg';
export { default as ProductDayIcon } from 'app/assets/icons/product-day-badge.svg';
export { default as CreditCardPlaceholderIcon } from 'app/assets/icons/credit-card-placeholder.svg';
export { default as CheckMark } from 'app/assets/icons/check-mark.svg';
