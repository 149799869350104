import type { ReadonlyURLSearchParams } from 'next/navigation';
import type { RecentSearchesType } from 'types/search';
import type { CheckoutComponentType } from 'types/cms/nahdi-middleware';
import { getAppSettings } from 'lib/cms/nahdi-middleware/app-settings';
import { getCookieInClient } from './cookie';
import { getLocalePathFromLanguageRegion } from './locale';
import { defaultLanguage, defaultRegion } from 'app/i18n/settings';
import { FunctionalityConfig } from 'types/cms/gss';

export const baseUrl = process.env.CF_PAGES_URL || process.env.NEXT_PUBLIC_URL;

export type LocaleCodesType = 'ar-sa' | 'en-sa' | 'ar-ae' | 'en-ae';
export type LanguageCodesType = 'ar' | 'en';
export type RegionCodesType = 'AE' | 'SA';

export const createUrl = (
  pathname: string,
  params: URLSearchParams | ReadonlyURLSearchParams
) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const convertToAbsoluteURL = (url: string) => {
  // Check if the URL is protocol-relative
  if (!url) return '';
  if (url.startsWith('//')) {
    // Modify the URL to use the https protocol
    return `https:${url}`;
  }
  if (url.startsWith('www')) {
    return `https://${url}`;
  }
  // Return the original URL if it's already absolute
  return url;
};

export const getTextByLang = (
  textObject: { en: string; ar: string },
  langCode: 'en' | 'ar'
) => {
  return textObject[langCode];
};

export const getRecentSearchesLocalStorage = () => {
  if (typeof localStorage === 'undefined') return;
  const recentSearches = JSON.parse(localStorage.getItem('recent_searches')!);
  if (recentSearches?.length) return recentSearches;
  return [];
};

export const setRecentSearchesLocalStorage = (searchQuery: string) => {
  if (searchQuery === '' || typeof localStorage === 'undefined') return;
  const recentSearches: RecentSearchesType = getRecentSearchesLocalStorage();
  if (recentSearches.some((item) => item === searchQuery)) return;
  if (recentSearches?.length === 5) recentSearches.splice(0, 1);
  recentSearches.push(searchQuery);
  localStorage.setItem('recent_searches', JSON.stringify(recentSearches));
};

export const getUrl = (
  url: string,
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const path = getLocalePathFromLanguageRegion(language, region);

  return `/${path}${url.startsWith('/') ? url : `/${url}`}`;
};

export const getPdpUrl = (
  productId: string | undefined,
  calculatedRelativeUrl: string | undefined,
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  if (!productId && !calculatedRelativeUrl) {
    return getUrl('/', language, region);
  }

  return calculatedRelativeUrl
    ? getUrl(calculatedRelativeUrl, language, region)
    : getUrl(`/pdp/${productId}`, language, region);
};

export const getComponentByProp = (
  componentHolder: CheckoutComponentType,
  prop: string,
  value: string
): CheckoutComponentType | undefined => {
  return componentHolder?.components?.find(
    (component: CheckoutComponentType) =>
      component?.[prop as keyof CheckoutComponentType] === value
  );
};

export const scrollToTarget = (elementId: string) => {
  const targetDiv = document.getElementById(elementId);
  if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const getPharmacistUrl = async (
  functionalityConfig: FunctionalityConfig
) => {
  const customerAccessToken = getCookieInClient('customerAccessToken');
  const baseUrl = functionalityConfig?.features?.e_pharmacist_base_link;
  const pharmacistUrl = customerAccessToken
    ? baseUrl?.replace('{{access_token}}', customerAccessToken)
    : '';
  return pharmacistUrl;
};

export async function getAlgoliaFunctionalityConfig(
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const { functionalityConfig } = await getAppSettings(language, region);

  return (
    functionalityConfig?.['algoliaSettings']?.indexes?.[region] ||
    functionalityConfig?.['algoliaSettings']?.indexes?.[defaultRegion] ||
    {}
  );
}

export async function getAlgoliaIndexName(
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const indexes = await getAlgoliaFunctionalityConfig(language, region);
  return indexes[language] || indexes[defaultLanguage] || '';
}
