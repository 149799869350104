'use client';

import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  document.body.classList.add('overflow-hidden');
  return wrapperElement;
}

const ReactPortal = ({
  children,
  wrapperId = 'react-portal-wrapper',
}: {
  children: React.ReactNode;
  wrapperId?: string;
}): any => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    let element = document.getElementById(wrapperId) as HTMLElement;
    let modalCreated = false;

    if (!element) {
      modalCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      // delete the programatically created element if it was created
      if (modalCreated && element.parentNode) {
        element.parentNode.removeChild(element);
        document.body.classList.remove('overflow-hidden');
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return <RemoveScroll>{createPortal(children, wrapperElement)}</RemoveScroll>;
};

export default ReactPortal;
