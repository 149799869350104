import { getCmsCollection } from 'lib/nahdi-middleware';
import { AppSettings } from 'types/cms/gss';
import {
  AppSettingsItemMdType,
  AppSettingsMdType,
  DownloadAppConfigType,
  FunctionalityConfigItemType,
  IMaintenanceMode,
} from 'types/cms/nahdi-middleware';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const getAppSettings = async (
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const apiData: AppSettingsMdType = await getCmsCollection(
    'fetch-system-settings',
    '',
    language,
    region
  );

  const mappedData: AppSettings = apiData.template[0] as AppSettingsItemMdType;

  const globalPrescriptionModal = apiData.template.find(
    (item: AppSettingsItemMdType) => item?.id === 'prescription-popup'
  );

  const functionalityConfig = apiData.template.find(
    (item: FunctionalityConfigItemType) =>
      item?.id === 'functionality-configuration'
  )?.data;

  const maintenanceMode: IMaintenanceMode | undefined = (
    apiData as any
  ).template.find((item: any) => item?.id === 'maintenance-mode');

  const downloadAppConfig = apiData.template.find(
    (item: DownloadAppConfigType) => item?.id === 'web-download-app-popup'
  )?.data;

  return {
    ...mappedData,
    globalPrescriptionModal,
    functionalityConfig,
    maintenanceMode,
    downloadAppConfig,
  };
};
