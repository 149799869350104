import { LocaleCodesType, LanguageCodesType, RegionCodesType } from 'utils';
import { NextRequest } from 'next/server';
import {
  locales,
  defaultLocale,
  defaultLanguage,
  defaultRegion,
} from 'app/i18n/settings';

export const getLanguageRegion = (
  locale: LocaleCodesType
): { language: LanguageCodesType; region: RegionCodesType } => {
  if (!locale) {
    locale = defaultLocale;
  }

  if (!locale.includes('-')) {
    return { language: defaultLanguage, region: defaultRegion };
  }

  const [language, region] = locale.split('-');
  return {
    language: language as LanguageCodesType,
    region: (region || '').toUpperCase() as RegionCodesType,
  };
};

export const getLocalePath = (req: NextRequest) => {
  // Check if there is any supported locale in the pathname
  const { pathname } = req.nextUrl;
  const locale = locales.find(
    (locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`
  );

  return locale;
};

export const getLanguageRegionFromNextRequest = (req: NextRequest) => {
  const locale = getLocalePath(req);

  return getLanguageRegion(locale as LocaleCodesType);
};

export const getLocalePathFromLanguageRegion = (
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  return `${language}-${region.toLowerCase()}`;
};

export const getLocaleISOFromLanguageRegion = (
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  return `${language}-${region}`;
};
